<template>
  <section class="min-w1600">
    <div class="searchwrap">
       <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.applyDate') }}</span></div>
           <date-selector :class="'searchbar-content'"
                          @setStartDate="setStartDate"
                          @setEndDate="setEndDate"
                          :startDefault="startDefault"
                          :endDefault="endDefault"
                          :configs="dateConfigs" /><!-- :isOldYn="true" @setOldYn="setOldYn" :oldYn="reqData.oldYn" -->
         </div>
       </div>
       <div class="searchBar">
          <div class="box-ui-select searchbar-content">
            <div class="title"><span>{{ $t('table.head.memType') }}</span></div>
            <select class="reqMember" v-model="reqData.userType">
              <option value="">{{ $t('searchArea.entire') }}</option>
              <option value="N">{{ $t('searchArea.user') }}</option>
              <option value="P">{{ $t('searchArea.partner') }}</option>
            </select>
          </div>
          <div class="box-ui-select searchbar-content">
            <div class="title"><span>{{ $t('searchArea.listType') }}</span></div>
            <select v-model="reqData.listType">
              <option value="">{{ $t('searchArea.entire') }}</option>
              <option value="2">{{ $t('searchArea.payment') }}</option>
              <option value="-2">{{ $t('searchArea.retrieval') }}</option>
            </select>
          </div>
          <div class="box-ui-select searchbar-content">
            <div class="title"><span>{{$t('searchArea.id')}}</span></div>
            <div>
              <input type="text" class="mr-5" v-model="reqData.memId" :placeholder="$t('searchArea.id')" />
              <button class="btn-search" type="button" @click="pageSearch">
                 <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
          <div class="sumWrap">
            <span>{{ $t('common.sumAdmin') }}</span>
            <span>{{ numberWithCommas(total) }}</span>
          </div>
       </div>
       <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <div class="tab-wrap">
        <button type="button" class="tab-item" :class="{ active: reqData.searchType === 'cash' }" @click="changeSearchType('cash')">{{ $t('button.cashList') }}</button>
        <button type="button" class="tab-item" :class="{ active: reqData.searchType === 'point' }" @click="changeSearchType('point')">{{ $t('button.pointList') }}</button>
      </div>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody v-if="applyList.length !== 0">
            <tr v-for="(item, idx) in applyList" :key="idx">
              <td>
                <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
              </td>
              <td>
                <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.memId)">{{ item.memNick }}</button>
              </td>
              <td>{{ item.memName }}</td>
              <td>
                 <div class="topwrap" v-if="item.topUserList">
                    <select class="select">
                       <template v-for="(partner, index) in item.topUserList">
                          <option class="option">
                             <span class="topbox">[{{computedPartnerLevel(partner)}}] </span>
                             <span :class="item.partnerLevel">{{partner.recommenderId}}</span>
                          </option>
                       </template>
                    </select>
                    <i class="icon">+</i>
                 </div>
              </td>
              <td :class="item.partnerLevel">
                 <span class="box">{{computedPartnerLevel(item)}}</span>
              </td>
              <td>{{ item.searchType }}</td>
              <template v-if="item.listType === '2'">
                <td>{{ $t('button.payment') }}</td>
              </template>
              <template v-else>
                <td>{{ $t('searchArea.retrieval') }}</td>
              </template>
              <td>{{ item.listTypeDesc }}</td>
              <td class="roboto">{{ numberWithCommas(item.balance) }}</td>
              <td v-if="item.amount > 0" class="fc-red roboto">
                 {{ numberWithCommas(item.amount) }}
              </td>
              <td v-if="item.amount < 0" class="fc-blue roboto">
                 {{ numberWithCommas(item.amount) }}
              </td>
              <td class="roboto">{{ numberWithCommas(Number(item.balance) + Number(item.amount)) }}</td>
              <td class="roboto">{{ item.regDate }}</td>
              <td>{{ item.memo }}</td>
            </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="13">
              <span class="notice-tableDataEmpty">{{$t('txt.noData')}}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="applyList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>

import { getDateStr, numberWithCommas } from '@/libs/utils.js'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import { adminCashPointList } from '@/api/member'
import Pagination from '@/components/common/Pagination'
import Memo from '@/components/common/memo'
import { PARTNER_LEVEL } from '@/libs/constants'

export default {
  name: 'loginList',
  components: {
    TableHead,
    DateSelector,
    SearchFilter,
    Pagination,
    Memo
  },
  props: {
    status: {
      type: String,
      default: 'all'
    }
  },
  watch: {
    $route: {
      async handler () {
        // this.reqData.searchType = this.$route.params.status || this.$route.query.status
        // await this.setTableData(1);
      }
    }
  },
  data: function () {
    return {
      total: 0,
      tableName: 'Basic',

      headInfo: {
        fstColumn: false,
        dataList: ['memId', 'memNick', 'memName', 'upper', 'level', 'searchType', 'listType', 'listTypeDesc', 'befMy', 'changeaftM', 'aftMy', 'regTime', 'memo']
      },
      cashMemo: [],
      reqData: {
        searchType: '',
        listType: '',
        page: 1,
        count_per_list: '30',
        startDate: '',
        endDate: '',
        userType: ''
      },
      pageInfo: {},
      applyList: [],
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      bankList: []
    }
  },
  methods: {
    computedPartnerLevel (item) {
      return PARTNER_LEVEL[item.partnerLevel]
    },
    numberWithCommas,
    pageSearch () {
      this.setTableData(this.pageInfo.page)
    },
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
        _date.setSeconds(59)
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    async changeSearchType (searchType) {
      this.reqData.searchType = searchType
      await this.setTableData()
    },
    async setTableData (pageNum) {
      this.reqData.page = pageNum || 1

      const res = await adminCashPointList(this.reqData)
      this.total = res.data.total.amountSum || 0
      const applyList = res.data.list
      const pageInfo = res.data.pageInfo
      if (pageInfo) {
        this.pageInfo = pageInfo
      }
      this.applyList = applyList
    }
  },
  async created () {
    this.reqData.searchType = this.$route.query.searchType || 'cash'
    this.setStartDate()
    this.setEndDate()
    this.siteInfo = this.getSiteInfo()
    await this.setTableData(1)
  }
}
</script>

<style scoped>
.flex-jus-bt {justify-content: space-between;}
.sumWrap{ display: flex; align-items: center; height: 26px;margin-left: auto;padding-right: 100px; }
.sumWrap span{ display: flex; height: 100%; padding: 15px 20px; align-items: center; box-sizing: border-box; font-size: 17px; }
.sumWrap span:nth-child(1) {background: #292a2c; color:#fff;border: 1px solid #292a2c;}
.sumWrap span:nth-child(2) {border: 1px solid #292a2c; color: #c17e2a; font-weight: 700;}
</style>
